// i18next-extract-mark-ns-start products-family

import { IProductFamily } from '@utils/types';
import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import ContactForm from '@components/ContactForm';
import Deck from '@components/Deck';
import PageSection from '@components/PageSection';
import Tile from '@components/Tile';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Breadcrumb } from '@components/Breadcrumbs';

const ProductFamiliesPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			// title=""
			// description=""
			/>

			<Banner
				title={t("title")}
				// title"
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<PageSection
				title={t("_ProductFamilies")}
				className="section--padding--adjust"
				layout="wide">
				<Deck>
					{data.productFamilies.edges.map(
						({ node }: { node: IProductFamily }, i: number) => (
							<Tile
								type="icon"
								key={i}
								title={t(node.titlekey) /* i18next-extract-disable-line */}
								image={{
									src: node.image,
									alt: t(node.titlekey) /* i18next-extract-disable-line */,
								}}
								link={node.link}>
								{t(node.contentkey) /* i18next-extract-disable-line */}
							</Tile>
						)
					)}
				</Deck>
			</PageSection>

			<PageSection theme="greytint" layout="fullwidth">
				<div className="col xl6 offset-xl3 l8 offset-l2 m10 offset-m1 s12 offset-s0">
					<ContactForm name="contact" title={t("_GetInTouch")} />
				</div>
			</PageSection>
		</Page>
	);
};

export default ProductFamiliesPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-family", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/banner-data.jpg" }) {
		...imageBreaker
	}
	productFamilies: allProductFamiliesJson {
		edges {
			node {
				titlekey
				contentkey
				image {
					...imageTile
				}
				link
			}
		}
	}
}
`;
